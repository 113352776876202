


































































import { Challenge } from '@bertazzoni/common/src/models/leaderboard.model'
import { Component, Vue } from 'vue-property-decorator'
import DatePicker from '@bertazzoni/back/components/DatePicker.vue'
import FormError from '@bertazzoni/common/src/components/FormError.vue'
import LeaderboardService from '@bertazzoni/common/src/services/LeaderboardService'

@Component({
  name: 'NewChallenge',
  components: { DatePicker, FormError }
})
export default class NewChallenge extends Vue {
  private newChallenge = {
    name: '',
    endDate: this.getDateNow(),
    startDate: this.getDateNow()
  } as Challenge
  private submitError = {
    startDate: false,
    endDate: false
  }

  async checkErrors() {
    const isValid = await this.$validator.validateAll()
    this.checkErrorDate('startDate')
    this.checkErrorDate('endDate')
    if (isValid && !this.submitError.startDate && !this.submitError.endDate) {
      this.submittingNewChallenge()
    }
  }

  checkErrorDate(startOrEnd): void {
    if (!this.newChallenge[startOrEnd] || this.newChallenge[startOrEnd].toString() === '') {
      this.submitError[startOrEnd] = true
    } else {
      this.submitError[startOrEnd] = false
    }
  }
  async submittingNewChallenge() {
    await LeaderboardService.createOne(this.newChallenge)
    this.goToLeaderBoard()
  }

  goToLeaderBoard() {
    this.$router.push('.')
  }

  private getDateNow() {
    return new Date()
  }
  private getStartingDate(newDate: Date): void {
    this.newChallenge.startDate = newDate ? new Date(newDate) : null
    this.checkErrorDate('startDate')
  }
  private getEndDate(newDate: Date): void {
    this.newChallenge.endDate = newDate ? new Date(newDate) : null
    this.checkErrorDate('endDate')
  }
}
